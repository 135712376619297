export default defineComponent({
  props: {
    labelLeft: {
      type: String,
      default: undefined
    },
    labelRight: {
      type: String,
      default: undefined
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {}
});